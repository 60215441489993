<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">福卡领取记录</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex flex-wrap align-items-center">
				<el-date-picker class="m-1" size="small" @change="changeTime" v-model="time" type="datetimerange"
					value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center m-2">
					<div class="label ">是否使用：</div>
					<el-select size="small" style="width: 120px" v-model="search.status" @change="toSearch"
						placeholder="是否使用">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="否" value="10"></el-option>
						<el-option label="是" value="20"></el-option>
					</el-select>
				</div>
				<div>
					<el-input style="width: 250px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="user_focas_id" label="福卡ID"></el-table-column>
				<el-table-column align="center" label="用户头像" width="110">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="color: red;font-weight: 800;">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="name" label="名称"></el-table-column>
				<el-table-column align="center" label="福卡图">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" width="125" label="状态">
					<template slot-scope="scope">
						<el-tag size="mini" v-if="scope.row.status == 10">未使用</el-tag>
						<el-tag size="mini" type="danger" v-if="scope.row.status == 20">已使用</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" width="125" label="使用时间">
					<template slot-scope="scope">
						<el-tag size="mini" v-if="scope.row.status == 10">未使用</el-tag>
						<template v-else>
							<span style="font-weight: 800;">{{scope.row.use_time}}</span>
						</template>			
					</template>
				</el-table-column>
				<el-table-column align="center" label="领取时间" width="180">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				lists: [],
				search: {
					s: 'store/focas/record',
					keyword:'',
					start_time: null,
					end_time: null,
				},
				time: [],
			}
		},
		created() {
			this.getList();
		},
		methods: {
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			toSearch() {
				this.search.page = 1;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
		},
	}
</script>
<style>

</style>
